function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-56"},[_vm._m(0),_c('DataTableV2',{attrs:{"headers":_vm.headers,"dataItems":_vm.courierDedicatedData},scopedSlots:_vm._u([{key:"0",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var props = rest;
return [_c('div',{staticClass:"flex h-full items-start text-sm mr-4"},[_c('span',[_vm._v(_vm._s(props.data))])])]}},{key:"1",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var props = rest;
return [_c('div',{staticClass:"flex h-full flex-col items-start gap-2 mr-4"},[(props.data.length === 0)?_c('span',[_vm._v("-")]):_vm._l((props.data),function(item,index){return _c('div',{key:index,staticClass:"tags",attrs:{"data-testid":("courier-dedicated-detail__chip-mp-" + index)},on:{"click":function($event){return _vm.onClickChip(item.areaId)}}},[_c('span',[_vm._v(_vm._s(item.areaName))]),_c('div',{staticClass:"caret-right"},[_c('CaretDownIcon',{attrs:{"fill":"#A7A8AA","width":"16","height":"16"}})],1)])})],2)]}},{key:"2",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var props = rest;
return [_c('div',{staticClass:"flex h-full flex-col items-start gap-2 mr-4"},[(props.data.length === 0)?_c('span',[_vm._v("-")]):_vm._l((props.data),function(item,index){return _c('div',{key:index,staticClass:"tags",attrs:{"data-testid":("courier-dedicated-detail__chip-ca-" + index)},on:{"click":function($event){return _vm.onClickChip(item.areaId)}}},[_c('span',[_vm._v(_vm._s(item.areaName))]),_c('div',{staticClass:"caret-right"},[_c('CaretDownIcon',{attrs:{"fill":"#A7A8AA","width":"16","height":"16"}})],1)])})],2)]}},{key:"3",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var props = rest;
return [_c('div',{staticClass:"flex h-full flex-col items-start gap-2 mr-4"},[(props.data.length === 0)?_c('span',[_vm._v("-")]):_vm._l((props.data),function(item,index){return _c('div',{key:index,staticClass:"tags",attrs:{"data-testid":("courier-dedicated-detail__chip-ca-" + index)},on:{"click":function($event){return _vm.onClickChip(item.areaId)}}},[_c('span',[_vm._v(_vm._s(item.areaName))]),_c('div',{staticClass:"caret-right"},[_c('CaretDownIcon',{attrs:{"fill":"#A7A8AA","width":"16","height":"16"}})],1)])})],2)]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row justify-between mt-1 mb-10"},[_c('h2',{staticClass:"font-bold text-gray-4"},[_vm._v("Detail Dedicated Courier")])])}]

export { render, staticRenderFns }