








































































import { Component, Vue, Watch } from 'vue-property-decorator'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import CaretDownIcon from '@/app/ui/assets/caret_down_icon.vue'
import controller from '@/app/ui/controllers/ManageCourierDedicatedController'
import { CourierDedicatedArea, CourierDedicatedListItem } from '@/domain/entities/ManageCourierDedicated'
import { ICourierDedicatedParams } from '@/app/infrastructures/misc/Constants/manageCourierDedicated'
import { IOptions, sortOptions } from '@/app/infrastructures/misc/Constants/scheduleOrder'

@Component({
  components: {
    DataTableV2,
    CaretDownIcon,
  },
})
export default class CourierDedicatedDetailPage extends Vue {
  controller = controller
  sortOptions: Array<IOptions> = sortOptions

  parameters: ICourierDedicatedParams = {
    page: 1,
    per_page: 10,
    sort: this.sortOptions[0],
    keyword: '',
  }

  headers: Array<string> = [
    'Nama Kurir',
    'Area Dedicated MP',
    'Area Dedicated CA',
    'Area Dedicated Client',
  ]

  courierDedicatedData: Array<
    Array<string | Array<number | CourierDedicatedArea>>
  > = []

  get courierId(): number {
    return Number(this.$route.params.courier.split('-')[0])
  }

  get courierName(): string {
    return this.$route.params.courier.split('-')[1]
  }

  get params(): Record<string, string | number> {
    return {
      page: this.parameters.page,
      per_page: this.parameters.per_page,
      keyword: this.courierName,
      sort_by: <string>this.parameters.sort.value,
    }
  }

  private fetchCourierDedicated(): void {
    controller.getAllCourierDedicated(this.params)
  }

  created(): void {
    this.fetchCourierDedicated()
  }

  private onClickChip(id: number): void {
    this.$router.push({
      name: 'AreaDedicatedDetailPage',
      params: { id: String(id) },
    })
  }

  @Watch('controller.dataCourierDedicatedList')
  setCourierDedicatedData(list: CourierDedicatedListItem[]): void {
    const data = list.find(
      item => item.courierId === this.courierId
    )

    if (data) {
      this.courierDedicatedData = [
        [
          `[${data.courierId}] ${data.courierName} (${data.courierType}) ${data.courierPhone}`,
          data.areaDedicatedMP || [],
          data.areaDedicatedCA || [],
          data.areaDedicatedClient || []
        ],
      ]
    }
  }
}
